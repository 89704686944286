import { PolicyModel, WidgetHelped, CodeItem } from '../types';
import { DateFNS } from './3rd';
import Consts from './consts';
import * as HostBy from './host-by';
import PATH from './path';
import ChineseFirstPy from './chinese-first-py';
import Envs from './envs';
import Ajax from './ajax';
import Apis from './apis';
import crypto from 'crypto';
import { FactorType } from '../business-components/ui-drive/ui-drive-engine';

const ALGORITHM = 'aes-128-ecb';
const SECURE_KEY = 'zbcdyrf123456789';
const SECURE_IV = '';
const AES_PREFIX = 'aes';

type QS = {
    ticket?: string | null;
    token?: string | null;
    delegated?: string | null;
    theme?: string | null;
    themeColor?: string | null;
    authFailTo?: string | null;
    rootUrlFrom?: string | null;
    urlFrom?: string | null;
    parcel?: string | null;
    openid?: string | null;
    planCode?: string | null;
    benefitLevel?: string | null;
    miniProgramSource?: string | null;
    sourceType?: string | null;//保单来源
    thirdDeskHome?: string;
    thirdDeskLogin?: string;
    actionType?: 'share' | 'read' | null;
    gluttonTraceNo?: string;
    declaType?: string;//是否跳转第三方健康告知且回调
    channel?: string | null;//保单渠道
    enabled?: string; //代理人是否在第三方是否具备该产品的销售权限
    functionType?: string; //支持类型
    adviceQuoteId?: string;
    drDocStep?: string;
    pageStep?: string;
    globalRequestId?: string;
    productCode?: string;
    coverageCode?: string;
    returnUrl?: string;
    factor?: string;//用于临时用参传参，无需明确具体业务意义
    hideWechatMenu?: string; //是否隐藏微信分享菜单标识,需要的话传Y
    url?: string;
    vendor?: string;
};

/**
 * copy from https://github.com/AceMetrix/jquery-deparam
 */
const deparam = (params?: string | null, coerce: boolean = false) => {
    var obj: any = {};
    var coerce_types: any = { true: !0, false: !1, null: null };

    // If params is an empty string or otherwise falsy, return obj.
    if (!params) {
        return obj;
    }

    // Iterate over all name=value pairs.
    params
        .replace(/\+/g, ' ')
        .split('&')
        .forEach(function (v) {
            var param: string[];
            let firstEq = v.indexOf('=');
            let lastEq = v.lastIndexOf('=');
            //只有一个等号
            if (firstEq == lastEq) {
                param = v.split('=');
            } else {
                param = [
                    v.substring(0, firstEq),
                    v.substring(firstEq + 1)
                ]
            }
            var key: string = decodeURIComponent(param[0]);
            var val: any;
            var cur: any = obj;
            var i: number = 0;
            // If key is more complex than 'foo', like 'a[]' or 'a[b][c]', split it
            // into its component parts.
            var keys: any[] = key.split('][');
            var keys_last: number = keys.length - 1;

            // If the first keys part contains [ and the last ends with ], then []
            // are correctly balanced.
            if (/\[/.test(keys[0]) && /\]$/.test(keys[keys_last])) {
                // Remove the trailing ] from the last keys part.
                keys[keys_last] = keys[keys_last].replace(/\]$/, '');

                // Split first keys part into two parts on the [ and add them back onto
                // the beginning of the keys array.
                keys = keys
                    .shift()
                    .split('[')
                    .concat(keys);

                keys_last = keys.length - 1;
            } else {
                // Basic 'foo' style key.
                keys_last = 0;
            }

            // Are we dealing with a name=value pair, or just a name?
            if (param.length === 2) {
                val = decodeURIComponent(param[1]);

                // Coerce values.
                if (coerce) {
                    val =
                        val && !isNaN(val) && +val + '' === val
                            ? +val // number
                            : val === 'undefined'
                                ? undefined // undefined
                                : coerce_types[val] !== undefined
                                    ? coerce_types[val] // true, false, null
                                    : val; // string
                }

                if (keys_last) {
                    // Complex key, build deep object structure based on a few rules:
                    // * The 'cur' pointer starts at the object top-level.
                    // * [] = array push (n is set to array length), [n] = array if n is
                    //   numeric, otherwise object.
                    // * If at the last keys part, set the value.
                    // * For each keys part, if the current level is undefined create an
                    //   object or array based on the type of the next keys part.
                    // * Move the 'cur' pointer to the next level.
                    // * Rinse & repeat.
                    for (; i <= keys_last; i++) {
                        key = keys[i] === '' ? cur.length : keys[i];
                        cur = cur[key] =
                            i < keys_last
                                ? cur[key] || (keys[i + 1] && isNaN(keys[i + 1]) ? {} : [])
                                : val;
                    }
                } else {
                    // Simple key, even simpler rules, since only scalars and shallow
                    // arrays are allowed.

                    if (Object.prototype.toString.call(obj[key]) === '[object Array]') {
                        // val is already an array, so push on the next value.
                        obj[key].push(val);
                    } else if ({}.hasOwnProperty.call(obj, key)) {
                        // val isn't an array, but since a second value has been specified,
                        // convert val into an array.
                        obj[key] = [obj[key], val];
                    } else {
                        // val is a scalar.
                        obj[key] = val;
                    }
                }
            } else if (key) {
                // No value was defined, so set something meaningful.
                obj[key] = coerce ? undefined : '';
            }
        });

    return obj;
};

/**
 * copy from https://github.com/knowledgecode/jquery-param
 */
const param = (a: any): string => {
    var s: string[] = [];
    var add = function (k: string, v: any) {
        v = typeof v === 'function' ? v() : v;
        v = v === null ? '' : v === undefined ? '' : v;
        s[s.length] = encodeURIComponent(k) + '=' + encodeURIComponent(v);
    };
    var buildParams = function (prefix: string, obj: any) {
        var i, len, key;

        if (prefix) {
            if (Array.isArray(obj)) {
                for (i = 0, len = obj.length; i < len; i++) {
                    buildParams(
                        prefix + '[' + (typeof obj[i] === 'object' && obj[i] ? i : '') + ']',
                        obj[i]
                    );
                }
            } else if (String(obj) === '[object Object]') {
                for (key in obj) {
                    buildParams(prefix + '[' + key + ']', obj[key]);
                }
            } else {
                add(prefix, obj);
            }
        } else if (Array.isArray(obj)) {
            for (i = 0, len = obj.length; i < len; i++) {
                add(obj[i].name, obj[i].value);
            }
        } else {
            for (key in obj) {
                buildParams(key, obj[key]);
            }
        }
        return s;
    };

    return buildParams('', a).join('&');
};


/**
 * 工具类
 */
class Utils {
    /**
     * 将指定的参数转换为数组.
     * 如果参数是数组, 直接返回.
     * 如果参数是null/undefined, 返回空数组.
     * 其他返回只包含一个元素(参数本身)的数组
     */
    toArray<T>(any: null | undefined | T | T[]): T[] {
        return Array.isArray(any) ? any : any == null ? [] : [any];
    }
    /**
     * 产生一个包含指定元素和长度的数组
     */
    times<T>(element: T, times?: number): T[] {
        const ret = new Array<T>(times || 1);
        ret.fill(element, 0, ret.length);
        return ret;
    }
    /**
     * 截取日期
     */
    truncateAsDate(date: string): string {
        return date ? date.substring(0, 11) : date;
    }
    /**
     * 对指定时间进行计算. 如果指定时间为null, 使用当前时间.
     * offset格式: /[yMdhms][+-]?\d+/
     */
    calculateDate(date: string | Date, offset: string): string {
        if (date === null) {
            date = new Date();
        } else if (this.isString(date)) {
            date = DateFNS.parse(date);
        }
        const regexp = /[yMdhms][+-]?\d+/g;
        const partRegexp = /([yMdhms])([+-]?)(\d+)/;
        const value = offset.match(regexp)!.reduce((date, operation) => {
            const match = operation.match(partRegexp)!;
            const part = match[1];
            const type = match[2];
            const variable = (match[3] as any) * (type === '-' ? -1 : 1);
            switch (part) {
                case 'y':
                    return type === ''
                        ? DateFNS.setYear(date, variable)
                        : DateFNS.addYear(date, variable);
                case 'M':
                    return type === ''
                        ? DateFNS.setMonth(date, variable)
                        : DateFNS.addMonth(date, variable);
                case 'd':
                    return type === ''
                        ? DateFNS.setDayOfMonth(date, variable)
                        : DateFNS.addDay(date, variable);
                case 'h':
                    return type === ''
                        ? DateFNS.setHour(date, variable)
                        : DateFNS.addHour(date, variable);
                case 'm':
                    return type === ''
                        ? DateFNS.setMinute(date, variable)
                        : DateFNS.addMinute(date, variable);
                case 's':
                    return type === ''
                        ? DateFNS.setSecond(date, variable)
                        : DateFNS.addSecond(date, variable);
                default:
                    throw new Error(
                        `Field[${type}] in offset[${offset}] for date calculation is not supported yet.`
                    );
            }
        }, date);

        return DateFNS.format(value, Consts.STANDARD_DATETIME_FORMAT);
    }
    /**
     * 金额格式化
     */
    formatAmount(currency?: number | string | null): string {
        if (currency != null) {
            return (currency + '').toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        } else {
            return '';
        }
    }
    isEmpty(o?: string | number | null): o is null | undefined {
        return !this.isNumber(o) && (this.isNull(o) || this.isUndefined(o) || o.length === 0);
    }
    isBlank(o?: string | number | null): o is string | null | undefined {
        return this.isEmpty(o) || (this.isString(o) && (o as string).trim().length === 0);
    }
    /**
     * 是否字符串
     */
    isString(o: any): o is string {
        return Object.prototype.toString.call(o).slice(8, -1) === 'String';
    }
    /**
     * 是否数字
     */
    isNumber(o: any): o is number {
        return Object.prototype.toString.call(o).slice(8, -1) === 'Number';
    }
    /**
     * 是否boolean
     * @param {*} o
     */
    isBoolean(o: any): o is boolean {
        return Object.prototype.toString.call(o).slice(8, -1) === 'Boolean';
    }
    /**
     * 是否函数
     * @param {*} o
     */
    isFunction(o: any): o is Function {
        return Object.prototype.toString.call(o).slice(8, -1) === 'Function';
    }
    /**
     * 是否为null
     * @param {*} o
     */
    isNull(o: any): o is null {
        return Object.prototype.toString.call(o).slice(8, -1) === 'Null';
    }
    /**
     * 是否undefined
     */
    isUndefined(o: any): o is undefined {
        return Object.prototype.toString.call(o).slice(8, -1) === 'Undefined';
    }
    /**
     * 是否对象
     */
    isObject(o: any): o is object {
        return Object.prototype.toString.call(o).slice(8, -1) === 'Object';
    }
    /**
     * 是否数组
     */
    isArray(o: any): o is any[] {
        return Array.isArray(o);
    }
    /**
     * 是否时间
     */
    isDate(o: any): o is Date {
        return Object.prototype.toString.call(o).slice(8, -1) === 'Date';
    }
    /**
     * 是否正则
     */
    isRegExp(o: any): o is RegExp {
        return Object.prototype.toString.call(o).slice(8, -1) === 'RegExp';
    }
    /**
     * 是否错误对象
     */
    isError(o: any): o is Error {
        return Object.prototype.toString.call(o).slice(8, -1) === 'Error';
    }
    /**
     * 是否Symbol函数
     */
    isSymbol(o: any): o is Symbol {
        return Object.prototype.toString.call(o).slice(8, -1) === 'Symbol';
    }
    /**
     * 是否Promise对象
     */
    isPromise(o: any): o is Promise<any> {
        return Object.prototype.toString.call(o).slice(8, -1) === 'Promise';
    }
    /**
     * 是否Set对象
     */
    isSet(o: any): o is Set<any> {
        return Object.prototype.toString.call(o).slice(8, -1) === 'Set';
    }
    /**
     * 是否是FormData对象
     */
    isFormData(o: any): o is FormData {
        return Object.prototype.toString.call(o).slice(8, -1) === 'FormData';
    }
    /**
     * 可否可以判断为false
     */
    isFalse(o: any): o is false {
        return !o || o === 'null' || o === 'undefined' || o === 'false' || o === 'NaN';
    }
    /**
     * 可否可以判断为true
     */
    isTrue(o: any): o is true {
        return !this.isFalse(o);
    }
    /**
     * 指定参数, 则解析; 否则获取当前location的search, 并转换为JSON对象.
     * 如果没有, 返回空JSON
     */
    fromQueryString(qs?: string | null): QS {
        if (!this.isNull(qs) && !this.isUndefined(qs)) {
            return deparam(qs);
        } else {
            const search = window.location.search;
            if (search && search !== '?') {
                return deparam(search.substring(1));
            } else {
                return {};
            }
        }
    }
    /**
     * 将指定的对象转换为query string
     */
    toQueryString(obj: any): string {
        return param(obj);
    }
    /**
     * 使用当前浏览器当前URI编码, 并且添加指定的query string
     */
    encodeCurrentURI(qs?: string | null): string {
        if (this.isNull(qs) || this.isUndefined(qs)) {
            return encodeURIComponent(window.location.origin + window.location.pathname);
        } else {
            return encodeURIComponent(`${window.location.origin}${window.location.pathname}?${qs}`);
        }
    }
    /**
     * 拼接姓名
     *
     * @param {string} firstName
     * @param {string} lastName
     */
    asName(firstName: string | null | undefined, lastName: string | null | undefined): string {
        if (this.hasOrientalChar(firstName) || this.hasOrientalChar(lastName)) {
            return (lastName || '') + (firstName || '');
        } else {
            return (firstName || '') + ' ' + (lastName || '');
        }
    }
    /**
     * 是否包含东方字符
     */
    hasOrientalChar(s: string | null | undefined): boolean {
        if (!s) {
            return false;
        }
        return (
            -1 !==
            s.split('').findIndex(char => {
                return char.charCodeAt(0) > 0x3040;
            })
        );
    }
    quitPolicyPage(invoker: WidgetHelped, policy: PolicyModel): void {
        const extensionData = policy.extensionData || {};
        if (extensionData.rootUrlFrom) {
            // 从其他地方跳过来的
            window.location.assign(extensionData.rootUrlFrom);
        } else if (extensionData.urlFrom) {
            // 从其他地方跳过来的
            const currentUrl = window.location.href.split('#')[0];
            if (extensionData.urlFrom !== currentUrl) {
                // 与当前url不一样, 直接跳
                window.location.assign(extensionData.urlFrom);
            } else if (window.location.href.indexOf('#') !== -1) {
                // 当前url有#, 退两步
                invoker
                    .getHelpers()
                    .getRouter()
                    .go(-2);
            } else {
                invoker
                    .getHelpers()
                    .getRouter()
                    .back();
            }
        } else {
            invoker
                .getHelpers()
                .getRouter()
                .back();
        }
    }
    /**
     * 生成文件
     * TODO 目前支持csv及txt格式, 后续如果需要生成其他格式文件, 例如图片, 再进行丰富及扩展
     *
     * @param fileName 文件名称
     * @param fileType 文件类型, 例如'text/csv'
     * @param fileContent 符合文件类型的文件内容
     */
    downloadFile(fileName: string, fileType: string, fileContent: string): void {
        // @ts-ignore
        if (window.navigator.msSaveOrOpenBlob) {
            // if browser is IE
            fileContent = '\ufeff' + fileContent;
            const IEBlob = new Blob([decodeURIComponent(encodeURI(fileContent))], {
                type: fileType + ';charset=utf-8;',
                lastModified: 0
            } as BlobOptions);
            //@ts-ignore
            navigator.msSaveBlob(IEBlob, fileName);
        } else {
            const link = document.createElement('a');
            // 解决大文件下载失败
            const blob = new Blob(['\ufeff' + fileContent], { type: fileType, lastModified: 0 } as BlobOptions);
            link.setAttribute('href', URL.createObjectURL(blob));
            link.setAttribute('download', fileName);

            document.body.appendChild(link);
            link.click();
        }
    }

    //废弃
    base64ImagetoFile(base64Image: string, filename: string): File {
        //将base64转换为文件
        var arr = base64Image.split(',')!,
            mime = arr[0].match(/:(.*?);/)![1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    //将base64转化成file
    dataURLtoFile(dataurl: string, filename: string): File {
        return this.blobToFile(this.dataURLtoBlob(dataurl), filename);
    }

    //将base64转换为blob
    dataURLtoBlob(dataurl: string): any {
        var arr = dataurl.split(','),
            mime = (arr[0].match(/:(.*?);/) || ['', ''])[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        // @ts-ignore
        return new Blob([u8arr], { type: mime, lastModified: 0 } as BlobOptions);
    }

    //将blob转换为file
    blobToFile(theBlob: any, fileName: string): any {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }


    transformPNG2JPEG(base64Image: string): Promise<string> {
        return new Promise(resolve => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d')!;

            const image = new Image();
            image.crossOrigin = 'anonymous';
            image.onload = function () {
                const { width, height } = image;
                canvas.width = width;
                canvas.height = height;
                ctx.fillStyle = '#fff';
                ctx.fillRect(0, 0, width, height);
                ctx.drawImage(image, 0, 0, width, height);
                resolve(canvas.toDataURL('image/jpeg', 1.0));
            };
            image.src = base64Image;
        });
    }
    copyTextToClipboard(s: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const textarea = document.createElement('textarea');
            // 一定要显示, 否则在模拟器上不能复制
            // textarea.style.display = 'none';
            textarea.style.position = 'fixed';
            textarea.style.height = '0';
            textarea.style.top = '-9999px';
            textarea.value = s;
            document.body.appendChild(textarea);
            textarea.select();
            textarea.setSelectionRange(0, s.length);
            const ret = document.execCommand('copy');
            document.body.removeChild(textarea);
            if (ret) {
                resolve();
            } else {
                reject();
            }
        });
    }
    getMobileSigninPagePath(tenantCode: string): string {
        const hostname = window.location.hostname;
        switch (hostname) {
            case 'mall-agency.eplbks.com':
            case 'mall-agency-uat.eplbks.com':
                return PATH.MALL_MOBILE_SIGN_IN;
            default:
                return PATH.MALL_MOBILE_SIGN_IN;
        }
    }
    log(message?: any, ...optionalParams: any[]): void {
        console.log(message, optionalParams);
    }
    info(message?: any, ...optionalParams: any[]): void {
        console.info(message, optionalParams);
    }
    trace(message?: any, ...optionalParams: any[]): void {
        console.trace(message, optionalParams);
    }
    warn(message?: any, ...optionalParams: any[]): void {
        console.warn(message, optionalParams);
    }
    error(message?: any, ...optionalParams: any[]): void {
        console.error(message, optionalParams);
    }
    table(...data: any[]): void {
        console.table(data);
    }

    validateIDCard(number: string) {
        if (!number) number = "";
        var card = number.trim();
        var str = "身份证";
        //是否为空
        if (card === '') {
            return '请输入' + str + '号';
        }
        //校验长度，类型
        if (this.isCardNo(card) === false) {
            return '您输入的' + str + '号码不正确，请重新输入';
        }
        //检查省份
        if (this.checkProvince(card) === false) {
            return '您输入的' + str + '号码不正确,请重新输入';
        }
        //校验生日
        if (this.checkBirthday(card) === false) {
            return '您输入的' + str + '号码生日不正确,请重新输入';
        }
    }
    //检查号码是否符合规范，包括长度，类型
    isCardNo(card: string) {
        //身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
        var reg = /(^\d{15}$)|(^\d{17}(\d|X)$)/;
        if (reg.test(card) === false) {
            return false;
        }

        return true;
    };
    //取身份证前两位,校验省份
    checkProvince(card: string) {
        var vcity = {
            11: "北京", 12: "天津", 13: "河北", 14: "山西", 15: "内蒙古",
            21: "辽宁", 22: "吉林", 23: "黑龙江", 31: "上海", 32: "江苏",
            33: "浙江", 34: "安徽", 35: "福建", 36: "江西", 37: "山东", 41: "河南",
            42: "湖北", 43: "湖南", 44: "广东", 45: "广西", 46: "海南", 50: "重庆",
            51: "四川", 52: "贵州", 53: "云南", 54: "西藏", 61: "陕西", 62: "甘肃",
            63: "青海", 64: "宁夏", 65: "新疆", 71: "台湾", 81: "香港", 82: "澳门", 91: "国外"
        };
        var province = card.substr(0, 2);
        //@ts-ignore
        if (this.isUndefined(vcity[province])) {
            return false;
        }
        return true;
    };
    //检查生日是否正确
    checkBirthday(card: string) {
        let len = card.length;
        //身份证15位时，次序为省（3位）市（3位）年（2位）月（2位）日（2位）校验位（3位），皆为数字
        // eslint-disable-next-line
        if (len == 15) {
            let re_fifteen = /^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/;
            let arr_data = card.match(re_fifteen);
            //@ts-ignore
            let year = arr_data[2];
            //@ts-ignore
            let month = arr_data[3];
            //@ts-ignore
            let day = arr_data[4];
            let birthday = new Date('19' + year + '/' + month + '/' + day);
            return this.verifyBirthday('19' + year, month, day, birthday);
        }
        //身份证18位时，次序为省（3位）市（3位）年（4位）月（2位）日（2位）校验位（4位），校验位末尾可能为X
        // eslint-disable-next-line
        if (len == 18) {
            let re_eighteen = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/;
            let arr_data = card.match(re_eighteen);
            //@ts-ignore
            let year = arr_data[2];
            //@ts-ignore
            let month = arr_data[3];
            //@ts-ignore
            let day = arr_data[4];
            let birthday = new Date(year + '/' + month + '/' + day);
            return this.verifyBirthday(year, month, day, birthday);
        }
        return false;
    };

    //校验日期
    verifyBirthday(year: string, month: string, day: string, birthday: Date) {
        var now = new Date();
        var now_year = now.getFullYear();
        //年月日是否合理
        if (birthday.getFullYear() === Number(year) && (birthday.getMonth() + 1) === Number(month) && birthday.getDate() === Number(day)) {
            //判断年份的范围（0岁到105岁之间)
            //@ts-ignore
            var time = now_year - year;
            if (time <= 105 && now > birthday) {
                return true;
            }
            return false;
        }
        return false;
    };


    /**
     * 获取当前ip地址，安卓能获取到，ios获取不到
     */
    getIPs(): Promise<String> {
        const DEFAULT_IP = '114.114.114.114';
        return new Promise((resolve, reject) => {
            try {
                var pc = new RTCPeerConnection({ iceServers: [] }), noop = function () { };
                pc.createDataChannel('');//create a bogus data channel
                let options = {
                    offerToReceiveAudio: false,
                    offerToReceiveVideo: true,
                    iceRestart: false
                } as RTCOfferOptions;

                pc.createOffer(options)
                    .then((desc) => {
                        pc.setLocalDescription(desc)
                    })
                    .catch((err) => {
                        console.log(err)
                    });

                pc.onicecandidate = function (ice) {
                    if (ice && ice.candidate && ice.candidate.candidate) {
                        var ips = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/.exec(ice.candidate.candidate);
                        console.log('my IP: ', ips);
                        if (ips && ips.length > 1) {
                            resolve(ips[1]);
                        } else {
                            resolve(DEFAULT_IP);
                        }
                    } else {
                        resolve(DEFAULT_IP);
                    }
                    pc.onicecandidate = noop;
                };
            } catch {
                resolve(DEFAULT_IP);
            }
        })


    };


    /**
     * 解决两个数相乘精度丢失问题
     * @param a
     * @param b
     * @returns {Number}
     */
    floatMul(a: number, b: number): number {
        var c = 0,
            d = a.toString(),
            e = b.toString();
        try {
            c += d.split(".")[1].length;
        } catch (f) { }
        try {
            c += e.split(".")[1].length;
        } catch (f) { }
        return Number(d.replace(".", "")) * Number(e.replace(".", "")) / Math.pow(10, c);
    }

    /**
     * 解决两个数相除精度丢失问题
     * @param a
     * @param b
     * @returns
     */
    floatDiv(a: number, b: number): number {
        var c, d, e = 0,
            f = 0;
        try {
            e = a.toString().split(".")[1].length;
        } catch (g) { }
        try {
            f = b.toString().split(".")[1].length;
        } catch (g) { }
        return c = Number(a.toString().replace(".", "")), d = Number(b.toString().replace(".", "")), this.floatMul(c / d, Math.pow(10, f - e));
    }


    /**
     * 前端分页
     * @param pageIndex
     * @param pageSize
     * @param totalData
     */
    getTableData(pageIndex: number = 1, pageSize: number = 10, totalData: any[] = []) {
        const { length } = totalData;
        const tableData = {
            items: [], //当前数值
            pageIndex, //第几页
            pageSize, //每页大小
            length //总长度
        } as {
            items: any[];
            pageIndex: number;
            pageSize: number;
            length: number;
        };
        if (pageSize >= length) {
            //pageSize大于等于总数据长度，说明只有1页数据或没有数据
            tableData.items = totalData;
            tableData.pageIndex = 1; //直接取第一页
        } else {
            //pageSize小于总数据长度，数据多余1页
            const num = pageSize * (pageIndex - 1); //计算当前页（不含）之前的所有数据总条数
            if (num < length) {
                //如果当前页之前所有数据总条数小于（不能等于）总的数据集长度，则说明当前页码没有超出最大页码
                const startIndex = num; //当前页第一条数据在总数据集中的索引
                const endIndex = num + pageSize - 1; //当前页最后一条数据索引
                tableData.items = totalData.filter(
                    (_, index) => index >= startIndex && index <= endIndex
                ); //当前页数据条数小于每页最大条数时，也按最大条数范围筛取数据
            } else {
                //当前页码超出最大页码，则计算实际最后一页的page，自动返回最后一页数据
                const size = parseInt(String(length / pageSize)); //取商
                const rest = length % pageSize; //取余数
                if (rest > 0) {
                    //余数大于0，说明实际最后一页数据不足pageSize，应该取size+1为最后一条的页码
                    tableData.pageIndex = size + 1; //当前页码重置，取size+1
                    tableData.items = totalData.filter(
                        (_, index) => index >= pageSize * size && index <= length
                    );
                } else if (rest === 0) {
                    //余数等于0，最后一页数据条数正好是pageSize
                    tableData.pageIndex = size; //当前页码重置，取size
                    tableData.items = totalData.filter(
                        (_, index) => index >= pageSize * (size - 1) && index <= length
                    );
                } //注：余数不可能小于0
            }
        }
        // console.log(tableData);
        return tableData;
    }

    //计算base64长度字符串的大小
    getBase64Size(base64: string): number {
        let len = base64.length;
        return len;
        // return Math.ceil(this.floatDiv(len, 4)) * 4;
    }



    /**转化为万为单位的数字 */
    transformTenThousand = (options: {
        value: number,
        precision?: number,
        unit?: string
    }): string => {
        const { value, precision = 0, unit = '' } = options;
        if (isNaN(value)) return '';
        let result = this.floatDiv(value, 10000).toFixed(precision);
        return [result, unit].join('');
    };

    /**
     *
     * @param value 是否是http://或https://开头的url地址
     * @returns
     */
    isHttpKey(value: string): boolean {
        return /^http[s]{0,1}:\/\/([\w.]+\/?)\S*/.test(value);
    }

    /**
     *
     * @param str 是否是base64字符传
     * @returns
     */
    isBase64Key(str: string): boolean {
        if (this.isBlank(str)) {
            return false;
        }
        //以data:开始的文件也认为是base64
        if (/^data:/.test(str) && (str as string).indexOf('base64') !== -1) return true;
        try {
            return btoa(atob(str)) == str;
        } catch (err) {
            return false;
        }
    }

    isWebassetKey(value: string): boolean {
        return /^\/webasset\/([\w.]+\/?)\S*/.test(value);
    }

    isStaticKey(value: string): boolean {
        return /^\/static\/([\w.]+\/?)\S*/.test(value);
    }


    //是否是sts的地址,不满足这3者的需要使用sts去请求
    isSTSKey(value: string): boolean {
        return this.isString(value) && !this.isBlank(value) && !this.isHttpKey(value) && !this.isBase64Key(value) && !this.isWebassetKey(value) && !this.isStaticKey(value);
    }

    /**
     *
     * @returns 生成32位uuid
     */
    generateUUID() {
        var s = [] as any[];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";

        var uuid = s.join("");
        return uuid;
    }

    generateFileType(type: string) {
		switch (type) {
			case 'application/pdf':
				return 'pdf';
			case 'image/jpeg':
				return 'jpg';
			case 'image/png':
				return 'png';
			case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
				return 'xlsx';
			case 'application/zip':
				return 'zip';
			case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return 'docx';
            case 'application/msword':
                return 'doc';
			case 'video/x-flv':
				return 'flv';
			case 'text/plain':
				return 'txt';
			case 'application/json':
				return 'json';
			case 'text/html':
				return 'html';
			default:
				return 'txt';
		}

	}

    /**
     * 图片转换成base64
     * @param url 图片路径
     */

    getUrlBase64(url: any, ext?: any): Promise<string> {
        return new Promise(resolve => {
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d')!;
            let dataURL;
            let image = new Image();
            image.crossOrigin = 'anonymous';
            image.src = url;
            image.onload = function () {
                const { width, height } = image;
                canvas.width = width;
                canvas.height = height;

                // @ts-ignore
                ctx.drawImage(image, 0, 0, width, height);
                dataURL = canvas.toDataURL("image/" + ext);
                // @ts-ignore
                canvas = null;
                resolve(dataURL);
            };

        });
    }


    getTenanIdByHostName = () => {
        const hosted = HostBy.asHostBy();
        switch (hosted.by) {
            case HostBy.HostBy.YDL:
                return 20006;
            case HostBy.HostBy.MYSELF:
                return 20006;
            default:
                return 20006;
        }
    };


    // 永达理文档 https://note.youdao.com/s/TKKxMFuH
    /**
     * 永达理原生App获取相册图片方法
     * @param callBackFunc 回调函数
     * @param scale 是否需要裁剪
     * @param crop 裁剪宽高比，必须为浮点型
     * @param uploadServer 是否上传到服务器，如果为true，则回调函数参数里面的img为图片外网临时url地址
     * @param ebaoAliyun osskey
     */
    callNativeCameraPicker = (
        callBackFunc: string | ((imageList: any) => void),
        crop?: true,
        scale?: number,
        uploadServer?: boolean,
        ebaoAliyun?: false,
) => {
        const json = {
            callBackFunc,
            crop,
            scale,
            uploadServer,
            ebaoAliyun: Envs.getAppEplbksVersion() >= '7.1.2'
        };
        console.log('callNativeCameraPicker', json);
        // window.cameraNativeCallBack('cameraNativeCallBack');
        //@ts-ignore
        EPLCamera.postMessage(JSON.stringify(json));
    };

    /**
     * 永达理原生App获取相册图片方法
     * @param maxImages 表示最多选择几张图片 最大限制为5张
     * @param callBackFunc 回调函数
     * @param scale 是否需要裁剪，仅当maxImages=1时候才能传递此值
     * @param crop 裁剪宽高比，必须为浮点型
     * @param ebaoAliyun osskey
     */
    callNativeImagePicker = (
        maxImages: number,
        callBackFunc: string | ((imageList: any) => void),
        scale?: number,
        crop?: true,
        uploadServer?: boolean,
        ebaoAliyun?: false,
    ) => {
        const json = {
            maxImages,
            callBackFunc,
            scale,
            crop,
            uploadServer,
            ebaoAliyun: Envs.getAppEplbksVersion() >= '7.1.2'
        };
        console.log('callNativeImagePicker', json);
        // window.photoNativeCallBack('photoNativeCallBack');
        //@ts-ignore
        EPLImagePicker.postMessage(JSON.stringify(json));
    };

    /**
     * 永达理原生App分享方法
     * @param options.scene 场景，timeline-朋友圈，session-朋友
     * @param options.type 分享的类型， image-分享图片，web-分享网页，text-分享文本，file-分享文件，video-分享视频
     * @param options.imageUrl 图片的url,必须以png或者jpg等图片格式结尾
     * @param options.thumbUrl 缩略图的url，网页分享时为网站的缩略图，视频分享时为网站的缩略图
     * @param options.text 文本
     * @param options.title 网页或者视频分享时候的标题
     * @param options.content 网页分享时网站的的介绍
     * @param options.webUrl 网页地址
     * @param options.videoUrl 分享视频时候的链接
     * @param options.fileUrl 分享文件时候的链接     
     */
     callNativeShare = (options: {
        scene: string,
        type: string,
        imageUrl?: string,
        thumbUrl?: string,
        text?: string,
        title?: string,
        content?: string,
        webUrl?: string,
        videoUrl?: string,
        fileUrl?: string,
    }) => {
        const json = { ...options };
        console.log('callNativeShare', json);
        //@ts-ignore
        EPLWechat.postMessage(JSON.stringify(json));
    };

	/** ossFile压缩接口调用, 压缩仅作为减小服务器压力，具体传递给保司的逻辑在提交保单前上传影像接口中
     *  添加了转格式的功能，并且返回的key值与传入会有变化
     */
    compressImageWithOssKeys = async (keys: string[]): Promise<{
        successList: string[],
        errorList: string[]
    }> => {
        if (keys.length === 0) return Promise.resolve({ successList: [], errorList: [] });
        try {
            // 前端仅压缩大于2M的图片, 接口单位为M
            const compressionList = keys.map(key => ({ ossKeyFile: key, maxSize: 2 }));
            const { body: { body: { successList, errorList } } } = await Ajax.post(Apis.DOC_COMPRESSION_DOCUMENT, { compressionList });
            return Promise.resolve({ successList, errorList });
        } catch {
            return Promise.reject();
        }
    }

    //传入字符串数组[{value:'你好',letter:'N'}]
    pySegSort(array: {
        value: string;
        letter?: string;
    }[], speciallArray?: { value: string; letter: string }[]) {
        let newArr = [] as any;
        array.forEach(item => {
            const { value } = item;
            if (this.toArray(speciallArray).length > 0) {
                if (speciallArray?.findIndex(item => item.value == value) !== -1) {
                    const special = speciallArray?.find(item => item.value == value);
                    const letter = special?.letter;
                    if (!this.isBlank(letter)) {
                        let newItem = {} as any;
                        //@ts-ignore
                        newItem.letter = letter.toUpperCase();
                        newItem.value = value;
                        newArr.push(newItem);
                        return;
                    }
                }
            }
            const pinYins = ChineseFirstPy.makePy(value.substring(0, 1));
            Array.from(new Set(pinYins)).forEach((pItem: any) => {
                let newItem = {} as any;
                if (!this.isBlank(pItem)) {
                    const letter = pItem.substring(0, 1);
                    newItem.letter = letter.toUpperCase();
                    newItem.value = value;
                    newArr.push(newItem);
                }
            });
        });
        return newArr;
    }

    /* 调用源生拍照以及相册 add by sanxiang Oct.15 2018
    * type  takePhoto拍照 pickPhoto从相册选择
    * 这是中融原生的拍照
    * 如果是永达理，请参考clpcp中utils.photoForYS
    */
    photoForYS = (type: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            try {
                // @ts-ignore
                if (window.__EBAO_JSSDK) {
                    // @ts-ignore
                    window.ydwf['openCamera']();
                    resolve(true);
                } else if (
                    // @ts-ignore
                    window.webkit &&
                    // @ts-ignore
                    window.webkit.messageHandlers &&
                    // @ts-ignore
                    window.webkit.messageHandlers.__EBAO_JSSDK
                ) {
                    // @ts-ignore
                    window.webkit['messageHandlers']['openCamera'].postMessage({});
                    resolve(true);
                }
            } catch (e) {
                console.log(e.message);
                reject()
            }
        })
    };
    //  获取回调img的base64。仅供中融app使用
    // 永达理app请参考clpcp
    getImgBack = (): Promise<any> => {
        return new Promise((resolve, reject) => {
            try {
                // @ts-ignore
                window.webkit_bgimage = (result: any) => {
                    if (result) {
                        // @ts-ignore
                        if (window.__EBAO_JSSDK) {
                            // 安卓直接返回就可以
                            resolve(result)
                        } else if (
                            // @ts-ignore
                            window.webkit &&
                            // @ts-ignore
                            window.webkit.messageHandlers &&
                            // @ts-ignore
                            window.webkit.messageHandlers.__EBAO_JSSDK
                        ) {
                            // ios返回的是一个json字符串，需要json格式化
                            resolve(JSON.parse(result))
                        }
                    }
                }
            } catch (e) {
                console.log(e.message);
                reject()
            }
        })
    };

    /* 永达理调用源生拍照以及相册
   */
    photoForYS2 = (type: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            let json = {
                "action": type,
                "requireCroppedImage": false,  // 是否裁剪
                "success": type == "takePhoto" ? "photoCallback" : "albumCallback",
                "defaultDevicePosition": "front"
            };
            let jsonStr = JSON.stringify(json);
            try {
                // @ts-ignore
                if (window.__EBAO_JSSDK) {
                    // @ts-ignore
                    window.__EBAO_JSSDK.callNative(jsonStr);
                    resolve(true);
                } else if (
                    // @ts-ignore
                window.webkit &&
                // @ts-ignore
                window.webkit.messageHandlers &&
                // @ts-ignore
                window.webkit.messageHandlers.__EBAO_JSSDK
                ) {
                    // @ts-ignore
                    window.webkit.messageHandlers.__EBAO_JSSDK.postMessage(jsonStr);
                    resolve(true);
                }
            } catch (e) {
                console.log(e.message)
                reject()
            }
        })
    }

    getBase64Image(url: string): Promise<string> {
        return new Promise(resolve => {
            var image = new Image();
            let base64 = '';
            image.src = url;
            image.crossOrigin = 'anonymous';

            image.onload = function () {
                var canvas = document.createElement('canvas');
                let width = image.width;
                let height = image.height;
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d')!;
                ctx.drawImage(image, 0, 0, width, height);
                base64 = canvas.toDataURL('image/jpeg');
                resolve(base64);
            };
        });
    }

    // 掩码方法
    maskCode(str: string, start: number, end: number): string {
        // 如果方法截取过长，则不掩码
        if (str && str.length < end) {
            return str;
        }
        const arr = str.split('');
        for (let i = start; i < end; i += 1) {
            arr[i] = '*'
        }
        return arr.join('')
    }

    rotateBase64Image = async (imageSrc: string, degree: number): Promise<string> => {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d')!;

            const quadrant = (degree / 90) % 4; //旋转象限
            const cutCoor = { sx: 0, sy: 0, ex: 0, ey: 0 }; //裁剪坐标

            const image = new Image();
            image.crossOrigin = 'anonymous';
            image.src = imageSrc;

            image.onload = function () {
                let imgW; //图片宽度
                let imgH; //图片高度
                let size; //canvas初始大小

                imgW = image.width;
                imgH = image.height;
                size = imgW > imgH ? imgW : imgH;

                canvas.width = size * 2;
                canvas.height = size * 2;
                // eslint-disable-next-line
                switch (quadrant) {
                    case 0:
                        cutCoor.sx = size;
                        cutCoor.sy = size;
                        cutCoor.ex = size + imgW;
                        cutCoor.ey = size + imgH;
                        break;
                    case 1:
                        cutCoor.sx = size - imgH;
                        cutCoor.sy = size;
                        cutCoor.ex = size;
                        cutCoor.ey = size + imgW;
                        break;
                    case 2:
                        cutCoor.sx = size - imgW;
                        cutCoor.sy = size - imgH;
                        cutCoor.ex = size;
                        cutCoor.ey = size;
                        break;
                    case 3:
                        cutCoor.sx = size;
                        cutCoor.sy = size - imgW;
                        cutCoor.ex = size + imgH;
                        cutCoor.ey = size + imgW;
                        break;
                }

                ctx.translate(size, size);
                ctx.rotate((degree * Math.PI) / 180);
                ctx.drawImage(image, 0, 0, imgW, imgH);

                var imgData = ctx.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey);
                if (quadrant % 2 === 0) {
                    canvas.width = imgW;
                    canvas.height = imgH;
                } else {
                    canvas.width = imgH;
                    canvas.height = imgW;
                }
                ctx.putImageData(imgData, 0, 0);
                resolve(canvas.toDataURL());
            };
            image.onerror = error => {
                reject(error);
            };
        });
    }

    getFileNameFromPath(path = '') {
		let fileNameWithSuffix = path.split('/').pop(),
			name = fileNameWithSuffix?.split('.').shift();
		return name ? decodeURIComponent(name) : '';
	}

    /** 跳转第三方链接，如果链接是goback返回方式，默认会刷新页面，最好保存一下当前订单状态 */
    jumpToExternalLink (link: string, toStep?: string) {
        const urlInfo = new URL(window.location.href);
        if (toStep) {
            urlInfo.searchParams.set("pageStep", toStep);
        }
        window.history.pushState(null, "", urlInfo.href);
        window.location.href = link;
    }

    /**
     * 从数组转换成系统可以使用的枚举值
     * @param values 
     */
    generateCodeItems(values?: string[], policy?: PolicyModel, factorType?: FactorType): CodeItem[] {
        if (!values || values.length === 0) {
            return [];
        }
        let tempCodeItems = [] as CodeItem[];
        if (values.length === 0) {
            return tempCodeItems;
        }
        if (values.includes('S') || values.includes('S1')) {
            tempCodeItems.push({ v: 'S1', l: '一次交清' });
        }
        if (values.includes('WL')) {
            tempCodeItems.push({ v: 'WL', l: '保终身' });
        }
        if (values.includes('1')) {
            tempCodeItems.push({ v: '1', l: '年交' });
        }
        if (values.includes('2')) {
            tempCodeItems.push({ v: '2', l: '半年交' });
        }
        if (values.includes('3')) {
            tempCodeItems.push({ v: '3', l: '季交' });
        }
        if (values.includes('4')) {
            tempCodeItems.push({ v: '4', l: '月交' });
        }
        if (values.includes('5')) {
            tempCodeItems.push({ v: '5', l: '趸交' });
        }

        // Y开头
        values.filter(value => {
            return value.startsWith('Y');
        }).forEach(value => {
            tempCodeItems.push({
                v: value, l: `${value.substring(1)}年`
            });
        })

        // M开头
        values.filter(value => {
            return value.startsWith('M');
        }).forEach(value => {
            tempCodeItems.push({
                v: value, l: `${value.substring(1)}月`
            });
        })

        // D开头
        values.filter(value => {
            return value.startsWith('D');
        }).forEach(value => {
            tempCodeItems.push({
                v: value, l: `${value.substring(1)}天`
            });
        })

        // A开头
        values.filter(value => {
            return value.startsWith('A') && Number.isInteger(Number(value.charAt(1)));
        }).forEach(value => {
            let l = `保至${value.substring(1)}周岁`;
            if (factorType === FactorType.CHARGE_PERIOD) l = `交至${value.substring(1)}周岁`;
            let item = {
                v: value, l
            };
            switch (policy?.vendorCode) {
                case 'yangg':
                    item.l = `至${value.substring(1)}周岁(保单周年日零时)`;
                    break;
                case 'zhongy2':
                    item.l = `至${value.substring(1)}周岁`;
                    break;
                default:
                    break;
            }
            tempCodeItems.push(item);
        })

        // AA开头
        values.filter(value => {
            return value.startsWith('AA');
        }).forEach(value => {
            tempCodeItems.push({
                v: value, l: `${value.substring(2)}周岁保单年度日`
            });
        })

        // AB开头
        values.filter(value => {
            return value.startsWith('AB');
        }).forEach(value => {
            tempCodeItems.push({
                v: value, l: `${value.substring(2)}周岁生日`
            });
        })

        // ：分隔
        values.filter(value => {
        	return value.includes(':');
        }).forEach(value => {
        	tempCodeItems.push({
        		v: value.substring(0, value.indexOf(':')), l: value.substring(value.indexOf(':') + 1)
        	});
        })

        return tempCodeItems;
    }

    // 年金用枚举
    generateCodeItems_ann(values?: string[]): CodeItem[] {
        if (!values || values.length === 0) {
            return [];
        }
        let tempCodeItems = [] as CodeItem[];
        if (values.length === 0) {
            return tempCodeItems;
        }
        if (values.includes('W')) {
            tempCodeItems.push({ v: 'W', l: '领终身' });
        }
        if (values.includes('NA')) {
            tempCodeItems.push({ v: 'NA', l: '无关' });
        }

        // 纯数字
        values.filter(value => {
            return Number.isInteger(Number(value));
        }).forEach(value => {
            tempCodeItems.push({
                v: value, l: `${value}年`
            });
        })

        // Y开头
        values.filter(value => {
            return value.startsWith('Y');
        }).forEach(value => {
            tempCodeItems.push({
                v: value, l: `${value.substring(1)}年后领取`
            });
        })

        // A开头
        values.filter(value => {
            return value.startsWith('A') && Number.isInteger(value.charAt(1));
        }).forEach(value => {
            tempCodeItems.push({
                v: value, l: `领至${value.substring(1)}周岁`
            });
        })

        // AA开头
        values.filter(value => {
            return value.startsWith('AA');
        }).forEach(value => {
            tempCodeItems.push({
                v: value, l: `${value.substring(2)}周岁`
            });
        })

        return tempCodeItems;
    }

    emailHide(email: string) {
        let avg;
        let splitted;
        let email1;
        let email2;
        splitted = email.split('@');
        email1 = splitted[0];
        avg = email1.length / 2;
        email1 = email1.substring(0, email1.length - avg);
        email2 = splitted[1];
        const star = "".padEnd(email.length - email1.length - email2.length - 1 > 0 ? email.length - email1.length - email2.length - 1 : 0, '*');
        return email1 + `${star}@` + email2; // 输出为11223***@qq.com
    }

    /**
	 * 
	 * 加密
	 * @returns 
	 */
	frontEncry(data: string) {
		var cipher = crypto.createCipheriv(ALGORITHM, SECURE_KEY, SECURE_IV);
		let encrypted = `${cipher.update(
			data, 
			'utf8',
			'hex',
		)}${cipher.final('hex')}`;
		return AES_PREFIX + encrypted;
	}

	/**
	 * 解密
	 * @returns 
	 */
	frontDecry(encryptedText: string) {
		if(new RegExp(`^${AES_PREFIX}`).test(encryptedText)){
			encryptedText = encryptedText.substring(AES_PREFIX.length);
			let decipher = crypto.createDecipheriv(ALGORITHM, SECURE_KEY, SECURE_IV);
			let decrypted = decipher.update(encryptedText, 'hex', 'utf8');
			decrypted += decipher.final('utf8');
			return decrypted;
		}
		return encryptedText;
	}

	generateSmsDecryptedBody(decryptedBody:string){
		return this.frontEncry(decryptedBody+DateFNS.format(new Date(),'YYYYMMDDHHmmss'));
	}

    // 过滤危险标签script
    handleHTML(html: string = '') {
        const pattern = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
        html = html.replace(pattern, '');
        return {
          __html: html
        };
    }
    
    // 从身份证号获取出生日期，默认格式为Consts.STANDARD_DATETIME_FORMAT_WITH_DEFAULT_TIME
    extractBirthdayFromIdCard(idCard: string = '', dateFormat: string = Consts.STANDARD_DATETIME_FORMAT_WITH_DEFAULT_TIME) {
        let birth;
        if (idCard.length === 18) {
          birth = idCard.substring(6, 14); // 提取出生日期部分
        } else if (idCard.length === 15) {
          birth = '19' + idCard.substring(6, 12); // 旧版身份证补齐年份
        } else {
          return '身份证号格式错误';
        }
        
        return DateFNS.format(`${birth.substring(0, 4)}-${birth.substring(4, 6)}-${birth.substring(6, 8)}`, dateFormat);
      }
      

}

export default new Utils();
